.card-report {
  border: 1px solid rgba(0,0,0,0.1);
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
  &-info {
    padding: 16px;
    max-width: 550px;
  }
  &-image {
    align-self: stretch;
    align-items: stretch;
    min-width: 300px;
    height: auto;
    background-repeat: no-repeat;
    background-size: cover;
  }
}
