.tooltip-title {
  display: flex;
  .button-circle {
    width: 24px;
    height: 24px;
    border-radius: 100px !important;
    text-align: center;
    background: $primary;
    font-size: 14px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 8px;
    &:hover {
      opacity: 0.5;
      cursor: pointer;
    }
  }
}
