.field-flex {
  display: flex;
  flex-direction: column;
  input, .p-dropdown, .p-calendar, .p-fileupload-choose, .p-inputnumber, .p-password, .p-multiselect {
    width: 100%;
  }
}
.p-multiselect-token-label {
  font-size: 12px;
}
