td {
  font-size: 12px;
  padding: 4px 16px !important;
}
th {
  font-size: 14px
}

.p-datatable-header > div{
  flex-wrap: wrap;
}
