.link-subsection {
  text-decoration: none;
  font-weight: 500;
  color: $primary;
  &:hover {
    font-weight: 700;
  }
}
.link-download {
  text-decoration: none;
  border: 1px solid $primary;
  border-radius: 30px;
  color: $primary;
  padding: 6px 12px;
  margin: 12px 0;
  display: inline-flex;
  &:hover{
    background: $primary;
    color: #fff;
  }
}
